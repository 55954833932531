<template>
  <div class="relative" v-on-click-outside="clearMatches" ref="el">
    <lf-input
      :placeholder="placeholder"
      :value="innerValue"
      :name="name"
      :disabled="disabled"
      :readonly="readonly"
      :noAutocomplete="true"
      @keyReleased="handleInput"
      @keydown="handleKeydown"
      @blur="handleBlur"
    />
    <icon-base
      v-if="arrow && !loading"
      :icon="arrowIcon"
      class="arrow"
      width="10"
      height="7"
    />
    <loader
      class="typeahead-loader"
      :isLoading="loading"
      size="20"
      :z-index="40"
      backgroundColor="transparent"
    />
    <div
      class="transition-all flex flex-col max-h-60 absolute bg-white w-full rounded shadow-lg"
      :class="shouldShowDropdown ? 'top-full z-40' : 'top-9 opacity-0 -z-1'"
    >
      <slot name="header" :value="innerValue"></slot>
      <ul class="overflow-auto">
        <li
          v-for="(suggestion, index) in suggestionMatches"
          :key="index"
          :ref="registerSuggestionRef"
          class="px-3 py-2 whitespace-nowrap cursor-pointer text-gray-700"
          :class="{ 'bg-gray-100': activeIndex === index }"
          @mouseenter="activeIndex = index"
          @mousedown.prevent.stop
          @click="selectSuggestion(suggestion)"
        >
          <slot name="suggestion" :suggestion="suggestion">
            {{ mapSuggestion(suggestion) }}
          </slot>
        </li>
      </ul>
      <slot name="footer" :value="innerValue"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISmartyStreetsSuggestion } from "@/models/common";
import type { PropType, ComponentPublicInstance } from "vue";
import { ref, watch, computed } from "vue";

const emit = defineEmits(["update:modelValue", "select"]);

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  modelValue: {
    type: String
  },
  placeholder: {
    type: String,
    default: ""
  },
  suggestions: {
    type: Array as PropType<ISmartyStreetsSuggestion[]>,
    default: () => []
  },
  mapSuggestion: {
    type: Function as PropType<
      (suggestion: ISmartyStreetsSuggestion) => string
    >,
    default: (suggestion: ISmartyStreetsSuggestion) => suggestion
  },
  customSearch: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false
  },
  readonly: {
    type: Boolean,
    required: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  arrow: {
    type: Boolean,
    default: false
  }
});

const el = ref<HTMLElement | null>(null);
const suggestionMatches = ref<ISmartyStreetsSuggestion[]>([]);
const suggestionRefs = ref<HTMLElement[]>([]);
const innerValue = ref<string | undefined>("");
const activeIndex = ref(0);

const normalizeSearch = (str?: string) =>
  (str && str.toLowerCase().trim()) || "";

const shouldShowDropdown = computed(
  () =>
    (Boolean(innerValue.value) && innerValue.value !== props.modelValue) ||
    suggestionMatches.value.length > 0
);

const arrowIcon = computed<string>(() => {
  if (!props.arrow) {
    return "";
  }
  if (shouldShowDropdown.value) {
    return "chevron-down";
  }
  return "chevron-up";
});

const normalizedSearchTerm = computed(() => normalizeSearch(innerValue.value));

const registerSuggestionRef = (
  el: Element | ComponentPublicInstance | null
) => {
  if (!(el instanceof HTMLElement)) return;
  suggestionRefs.value.push(el);
};

const clearMatches = () => {
  activeIndex.value = 0;
  suggestionRefs.value = [];
  suggestionMatches.value = [];
};

/**
 * FYI, this is a pretty rudimentary search function.
 * It's likely that you'll want to use a more robust library for client side searching (i.e. Fuse.js)
 * This is mostly here as a placeholder for future use cases, as most typeaheads will probably use an API
 */
const search = () => {
  suggestionMatches.value = props.suggestions.filter((item) =>
    props.mapSuggestion(item).toLowerCase().includes(normalizedSearchTerm.value)
  );
};

const handleInput = (value: string | undefined) => {
  innerValue.value = value;

  emit("update:modelValue", innerValue.value);

  if (!innerValue.value) {
    clearMatches();
    return;
  }

  if (props.customSearch) {
    return;
  }

  search();
};

const handleBlur = ({ relatedTarget }: FocusEvent) => {
  if (relatedTarget instanceof Node && !el.value?.contains(relatedTarget)) {
    clearMatches();
  }
};

const selectSuggestion = (suggestion: ISmartyStreetsSuggestion) => {
  if (!suggestion) return;
  emit("select", suggestion);
  clearMatches();
};

/**
 * Checks whether a given suggestion is visible within the dropdown and viewport
 */
const isSuggestionInViewport = (suggestionRef: HTMLElement) => {
  const { left, top, bottom } = suggestionRef.getBoundingClientRect();
  return [
    [left + 1, top + 1],
    [left + 1, bottom - 1]
  ].every(([x, y]) => document.elementFromPoint(x, y) === suggestionRef);
};

/**
 * Set prev/next suggestion as active using up/down arrow keys
 */
const handleArrowKey = (event: KeyboardEvent) => {
  if (!shouldShowDropdown.value) return;

  event.preventDefault();

  const directionModifier = event.key === "ArrowDown" ? 1 : -1;
  const matchesCount = suggestionMatches.value.length;
  const newActiveIndex =
    (matchesCount + activeIndex.value + directionModifier) % matchesCount;
  const activeSuggestionRef = suggestionRefs.value[newActiveIndex];

  if (activeSuggestionRef && !isSuggestionInViewport(activeSuggestionRef)) {
    activeSuggestionRef.scrollIntoView({ behavior: "smooth" });
  }

  activeIndex.value = newActiveIndex;
};

/**
 * Select the active item using the Enter key
 */
const handleEnterKey = (event: KeyboardEvent) => {
  const activeItem = suggestionMatches.value[activeIndex.value];

  if (!shouldShowDropdown.value || !activeItem) return;

  event.preventDefault();

  selectSuggestion(activeItem);
};

const handleKeydown = (event: KeyboardEvent) => {
  const { key } = event;

  if (key === "Enter") {
    handleEnterKey(event);
    return;
  }

  if (key === "ArrowDown" || key === "ArrowUp") {
    handleArrowKey(event);
    return;
  }

  if (key === "Escape" && shouldShowDropdown.value) {
    clearMatches();
    // If nested inside something else listening for an Esc press (i.e. Modal), prevent this from bubbling
    event.stopPropagation();
    return;
  }
};

// Keep the inner value in sync with modelValue
watch(
  () => props.modelValue,
  (modelValue) => {
    innerValue.value = modelValue;
  },
  { immediate: true }
);

// In custom mode, a change in suggestions means search results were returned
if (props.customSearch) {
  watch(
    () => props.suggestions,
    (suggestions) => {
      clearMatches();
      suggestionMatches.value = suggestions;
    },
    { immediate: true }
  );
}
</script>

<style scoped>
.typeahead-loader {
  left: auto;
  right: 1.5rem;
  pointer-events: none;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}
</style>
